module.exports = {
    saveToLocalStorage: function (key, value) {
        if (value) {
            localStorage.setItem(key, value);
        }
    },
    getDateDifferenceInMinutes: function (endDate, startDate) {
        //Get 1 min in milliseconds
        const oneMinInMillis = 1000 * 60;
        const timeDif = endDate - startDate;
        return Math.round(timeDif / oneMinInMillis);
    },
    getDateDifferenceInHours: function (endDate, startDate) {
        //Get 1 hour in milliseconds
        const oneHourInMillis = 1000 * 60 * 60;
        const timeDif = endDate - startDate;
        return Math.round(timeDif / oneHourInMillis);
    },
}