import React from 'react';
import logo_ct1 from './images/logo_ct1.png';
import logo_ct2 from './images/logo_ct2.png';
import logo_ct24 from './images/logo_ct24.png';
import logo_ctsport from './images/logo_ctsport.png';
import logo_ctd from './images/logo_ctd.png';
import logo_ctart from './images/logo_ctart.png';
import logo_nova from './images/logo_nova.png';
import logo_novaaction from './images/logo_novaaction.png';
import logo_novacinema from './images/logo_novacinema.png';
import logo_novagold from './images/logo_novagold.png';
import logo_prima from './images/logo_prima.png';
import logo_primacool from './images/logo_primacool.png';
import logo_primakrimi from './images/logo_primakrimi.png';
import logo_primalove from './images/logo_primalove.png';
import logo_primamax from './images/logo_primamax.png';
import logo_primazoom from './images/logo_primazoom.png';
import logo_ocko from './images/logo_ocko.jpg';
import logo_ockostar from './images/logo_ockostar.jpg';
import logo_ockoexpres from './images/logo_ockoexpres.jpg';

import { config } from './common/constants'
import './App.css';
import StationProgramme from './components/StationProgramme';
import LoadingScreen from './components/LoadingScreen';

const Util = require("./common/util.js");

class App extends React.Component{

  constructor(props) {
    console.log("CONSTRUCTOR");
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      minUnixTimestamp: null,
      programmeEntryList: []
    };
  }
  static getDerivedStateFromProps(props, state) {
    console.log("GET_DERIVED_STATE");
    return state;
  }
  componentDidUpdate() {
    console.log("COMPONENT_DID_UPDATE")
  }

  componentDidMount() {
    console.log("COMPONENT_DID_MOUNT");
    //this.parseXMLTV();
    const script = document.createElement("script");
    script.innerHTML = 'function openStation(stationName){'+
      //'console.log(stationName);' +
      'if (typeof Android !== \'undefined\') {Android.openStation(stationName);}'+
      'else if (typeof webkit !== \'undefined\'){webkit.messageHandlers.iOSNative.postMessage(stationName);}'+
      'else {console.log("This is not supported");}'+
      '}';
    script.innerHTML += 'var els = document.getElementsByClassName("stationLogo");' +
      'for (el of els) {' +
      //'console.log(el.id);'+
      'el.onclick=function(){openStation(this.id)};' + 
      '}' +
      'var rows = document.getElementsByClassName("programmeRow");' +
      'for (row of rows) {' +
      //'console.log(row.id.replace("_row",""));'+
      'row.onclick=function(){openStation(this.id.replace("_row",""))};' + 
      '}' +
      'function andScrollToNow() {' +
        'var tsNow = Math.round((new Date()).getTime()/1000);' +
        'var diff = tsNow - ' + this.state.minUnixTimestamp + ';' +
        'document.getElementById("programmeRowsWrapper").scrollLeft = Math.round(diff * ' + config.MULTIPLIER +');'+
      '}' ;
    //script.async = true;
    document.body.appendChild(script);

    var mustUpdate = false;
    var exceptionThrown = false;
    try {
      const lastUpdateStr = localStorage.getItem(config.LAST_UPDATE_KEY);
      const fetchedData = localStorage.getItem(config.FETCHED_DATA_KEY);
      if (lastUpdateStr) {
        const lastUpdate = new Date(lastUpdateStr);
        const dateNow = new Date();
        if ((Util.getDateDifferenceInMinutes(dateNow, lastUpdate) > config.MAX_DATA_AGE_IN_MINS) ||
          (fetchedData === null) || (fetchedData === undefined)
          ){
            mustUpdate = true;
            console.log("FETCHED DATA IS OLD");
          //this.fetchData();
        } else {
          console.log("NOT OLD.. displaying fetched data");
          //We'll read what we have
          var tvProgramme = JSON.parse(fetchedData);
          this.setState({
            isLoaded: true,
            programmeEntryList: tvProgramme,
            minUnixTimestamp: tvProgramme[config.UNIX_OFFSET_KEY],
          }, this.scrollToNow);
          //this.scrollToNow();
        }
      } else {
        console.log("NO LASTUPDATE DATE");
        mustUpdate = true;
      }
    } catch (e) {
      console.log(e);
      exceptionThrown = true;
    } finally {
      console.log("MUSTUPDATE:" + mustUpdate);
      if (mustUpdate || exceptionThrown) {
        this.fetchData();
      } 
    }

  }

  fetchData() {
    fetch(config.url.PROGRAM_API_URL)
      .then(res => res.json())
      .then(
        (result) => {
          //console.log("SUCCESS");
          //console.log(result);
          this.setState({
            isLoaded: true,
            programmeEntryList: result,
            minUnixTimestamp: result[config.UNIX_OFFSET_KEY],
          }, this.scrollToNow);

          //DATA FETCHED - Save them to localStorage
          Util.saveToLocalStorage(config.FETCHED_DATA_KEY, JSON.stringify(result));
          //Save also lastUpdate date
          Util.saveToLocalStorage(config.LAST_UPDATE_KEY, new Date());
          
          //this.timer = setInterval(this.scrollToNow, 10000)
          //this.scrollToNow();
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("ERROR");
          console.log(error);
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  scrollToNow = () => {
    console.log("SCROLLING TO NOW");
    var tsNow = Math.round((new Date()).getTime()/1000);
    var diff = tsNow - this.state.minUnixTimestamp;
    document.getElementById('programmeRowsWrapper')
      .scrollLeft = Math.round((diff * config.MULTIPLIER) - config.SCROLLING_RETURN_LEFT_OFFSET);
  }


  render() {
    console.log("RENDER");
    let loadingScreen;
    if (!this.state.isLoaded) {
      loadingScreen = <LoadingScreen />;
    } else {
      loadingScreen = null;
    }
    return (
      <div>
        <h4 style={{ padding: 0.5 + 'em', margin: 0, borderBottom: 'solid 1px black', textAlign: 'center' }}>Tato aplikace včetně nejnovějších aktualizací je momentálně pouze dostupná v <a href="https://appgallery.huawei.com/app/C101979231">Huawei App Gallery</a>.</h4>
        <div id="wrapper">
          {loadingScreen}
          <div id="header">
            <div className="stationLogo" id="ct1"><img src={logo_ct1} alt=""/></div>
            <div className="stationLogo" id="ct2"><img src={logo_ct2} alt=""/></div>
            <div className="stationLogo" id="ct24"><img src={logo_ct24} alt=""/></div>
            <div className="stationLogo" id="ct4"><img src={logo_ctsport} alt=""/></div>
            <div className="stationLogo" id="ctdart" style={{flexWrap: 'wrap', alignContent: 'center'}}>
              <img src={logo_ctd} alt=""/>
              <img src={logo_ctart} alt=""/></div>
            <div className="stationLogo" id="nova"><img src={logo_nova} alt=""/></div>
            <div className="stationLogo" id="novaaction"><img src={logo_novaaction} alt=""/></div>
            <div className="stationLogo" id="novacinema"><img src={logo_novacinema} alt=""/></div>
            <div className="stationLogo" id="novagold"><img src={logo_novagold} alt=""/></div>
            <div className="stationLogo" id="prima"><img src={logo_prima} alt=""/></div>
            <div className="stationLogo" id="primacool"><img src={logo_primacool} alt=""/></div>
            <div className="stationLogo" id="primakrimi"><img src={logo_primakrimi} alt=""/></div>
            <div className="stationLogo" id="primalove"><img src={logo_primalove} alt=""/></div>
            <div className="stationLogo" id="primamax"><img src={logo_primamax} alt=""/></div>
            <div className="stationLogo" id="primazoom"><img src={logo_primazoom} alt=""/></div>
            <div className="stationLogo" id="ocko"><img src={logo_ocko} alt=""/></div>
            {/*<div className="stationLogo" id="ockostar" style={{flexWrap: 'wrap', alignContent: 'center'}}>
              <img src={logo_ockostar} alt="" />
              <span style={{fontFamily:'Roboto, sans-serif', fontWeight: 'bold', fontSize: '2rem'}}>STAR</span>
            </div>*/}
            {/*<div className="stationLogo" id="ockoexpres" style={{flexWrap: 'wrap', alignContent: 'center'}}>
              <img src={logo_ockoexpres} alt="" />
              <span style={{fontFamily:'Roboto, sans-serif', fontWeight: 'bold', fontSize: '1.7rem'}}>EXPRES</span>
              </div>*/}
          </div>
          <div id="programmeRowsWrapper">
            <StationProgramme programmeEntryList={this.state.programmeEntryList['ct1']} stationid='ct1_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['ct2']} stationid='ct2_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['ct24']} stationid='ct24_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['ct4']} stationid='ct4_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['ctd']} stationid='ctdart_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['nova']} stationid='nova_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['novaaction']} stationid='novaaction_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['novacinema']} stationid='novacinema_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['novagold']} stationid='novagold_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['prima']} stationid='prima_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['primacool']} stationid='primacool_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['primakrimi']} stationid='primakrimi_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['primalove']} stationid='primalove_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['primamax']} stationid='primamax_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['primazoom']} stationid='primazoom_row'/>
            <StationProgramme programmeEntryList={this.state.programmeEntryList['ocko']} stationid='ocko_row'/>
            {/*<StationProgramme programmeEntryList={this.state.programmeEntryList['ockostar']} stationid='ockostar_row'/>*/}
            {/*<StationProgramme programmeEntryList={this.state.programmeEntryList['ockoexpres']} stationid='ockoexpres_row'/>*/}
          </div>
        </div>
      </div>
    );
  };
}

export default App;
