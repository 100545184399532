import React from 'react';
import vintageCounter from '../images/vintageCounter.gif';

class LoadingScreen extends React.Component {

  
 
  render() {
    

    return (
      <div id="loadingScreen">
        <img src={vintageCounter} alt="counter" id="loaderImage"/>
      </div>
    )
  }
}

export default LoadingScreen;