//Same values for DEV and PROD
const MULTIPLIER = 1/10;
const LAST_UPDATE_KEY = "lastUpdate";
const FETCHED_DATA_KEY = "fetchedData";
const UNIX_OFFSET_KEY = "unixOffset"; //value generated by server.js
const MAX_DATA_AGE_IN_MINS = 120;
const SCROLLING_RETURN_LEFT_OFFSET = 100;
const MIN_ENTRY_WIDTH = 100;

const prod = {
    url: {
     PROGRAM_API_URL: 'https://api.program.uzfulapps.com',
    },
    MULTIPLIER: MULTIPLIER,
    LAST_UPDATE_KEY,
    MAX_DATA_AGE_IN_MINS,
    FETCHED_DATA_KEY,
    UNIX_OFFSET_KEY,
    SCROLLING_RETURN_LEFT_OFFSET,
    MIN_ENTRY_WIDTH,
};
const dev = {
    url: {
        //PROGRAM_API_URL: 'http://test.api.program.uzfulapps.com'
        PROGRAM_API_URL: 'http://localhost:4321'
    },
    MULTIPLIER: MULTIPLIER,
    LAST_UPDATE_KEY, 
    MAX_DATA_AGE_IN_MINS,
    FETCHED_DATA_KEY,
    UNIX_OFFSET_KEY,
    SCROLLING_RETURN_LEFT_OFFSET,
    MIN_ENTRY_WIDTH,
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;