import React from 'react';
import { config } from '../common/constants'

class StationProgramme extends React.Component {

  printTime = (dateTimeStr) => {
    var dateTime = new Date(dateTimeStr);
    return dateTime.toLocaleTimeString("en-US", {timeZone: "Europe/Prague", hour12: false, hour: "numeric", minute: "numeric"});
    //return date.getHours() + ":" + date.getMinutes();
  }
  printDate = (dateTimeStr) => {
    var dateTime = new Date(dateTimeStr);
    return dateTime.toLocaleDateString("cs-CZ", {timeZone: "Europe/Prague", hour12: false, hour: "numeric", minute: "numeric"});
  }

  getDifferenceInMinutes = (end, start) => {
    var diff = null;
      try {
        var endDate = new Date(end);
        var startDate = new Date(start);
        const oneMinuteInMillis = 1000 * 60;
        const multiplier = 10;
        const timeDif = endDate - startDate;
        diff = Math.round(timeDif / oneMinuteInMillis) * multiplier;
      } catch (ex) {

      } finally {
        return diff;
      }
  }

  getDifferenceInSeconds = (end, start) => {
    var diff = null;
      try {
        var endDate = new Date(end);
        var startDate = new Date(start);
        const oneSecondInMillis = 1000;
        const timeDif = endDate - startDate;
        diff = Math.round(timeDif / oneSecondInMillis);
      } catch (ex) {

      } finally {
        return diff;
      }
  }

  getOffset = (start) => {
    var startDate = new Date(start);
    var todayDate = new Date();
    //console.log(start);
    //console.log(todayDate.getFullYear() + " " + (todayDate.getMonth()+1) + "  " +  todayDate.getDate());
    var todayDateNoTime = new Date(todayDate.getFullYear(), (todayDate.getMonth()+1), todayDate.getDate(), 0, 0, 0, 0);
    //console.log(todayDateNoTime);
    var diff = Math.round(this.getDifferenceInMinutes(startDate,todayDateNoTime));
    //console.log(diff);
    return diff;
  }
  getWidth = (programmeEntry) => {
    return Math.round(this.getDifferenceInSeconds(programmeEntry.end, programmeEntry.start) * config.MULTIPLIER);
  }
  getLeft = (programmeEntry) => {
    return Math.round(programmeEntry.startUnixTSDiff * config.MULTIPLIER);
  }
  isLiveNow = (start, end) => {
    var timeNow = new Date();
    var startTime = new Date(start);
    var endTime = new Date(end);
    //console.log(timeNow + " " + startTime + " " + endTime);
    if ((startTime <= timeNow) && (endTime >= timeNow)){//=> startTime && timeNow <= endTime) {
      //console.log("TRUE");
      return true;
    } else {
      //console.log("FALSE");
      return false;
    }
  }

  isTooSmall = (programmeEntry) => {
    return (this.getWidth(programmeEntry) < config.MIN_ENTRY_WIDTH);
  }
 
  render() {
    //const multiplier = 1/6;
    //console.log("PROPS");
    //console.log(this.props);
    
    var content;
    if (this.props.programmeEntryList) {
      content = this.props.programmeEntryList.map((programmeEntry, index) =>
        <div 
          key={index}
          className={
            'programmeEntry' + (this.isLiveNow(programmeEntry.start, programmeEntry.end)?' isLive':'')
          }
          style={
            {
              width: this.getWidth(programmeEntry) + 'px',
              left: this.getLeft(programmeEntry) + 'px',
              
            }
          }
          //style={index==0?{marginLeft: this.getOffset(programmeEntry.start) + 'px'}:{}}
          //style={index==0?{marginLeft: programmeEntry.startUnixTSDiff/6 + 'px'}:{}}
          > {/*programmeEntry.startUnixTSDiff + ' ' + (Math.round(programmeEntry.startUnixTSDiff*config.multiplier))*/}
            <div className='timeStart'>
                {/*this.printDate(programmeEntry.start)*/}
                {this.printTime(programmeEntry.start)}
            </div>
            <div className='timeEnd'>{/*this.printTime(programmeEntry.end)*/}</div>
            <div 
              className='title'
              style={this.isTooSmall(programmeEntry)?
                {
                  //fontSize: 'smaller',
                  //transform: 'rotate(90deg)',
                  //transformOrigin: 'left top 0',
                }
                :{}
              }
              >
                {programmeEntry.title}
            </div>
            {/*this.getWidth(programmeEntry)*/}
            {!this.isTooSmall(programmeEntry)?programmeEntry.desc:''} 
        </div>
      );
    } else {
      content = <div></div>;
    }

    return (
      <div className="programmeRow" id={this.props.stationid}>
        {content}
      </div>
    )
  }
}

export default StationProgramme;